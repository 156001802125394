<template>
  <div>
    <!-- register.html 12-55 -->
    <!--页面主体-->
    <div class="register">
      <h2>新用户注册</h2>
      <ul>
        <li>
          <span>邮箱：</span>
          <input
            type="text"
            id="uname"
            placeholder="请输入邮箱名"
            v-model="uname"
            @blur="checkName"
          />
          <em
            class="icon_error"
            style="display: inline;"
            v-if="unameStatus == 3 || unameStatus == 1 || unameStatus == 2"
          ></em>
          <em
            class="icon_ok"
            style="display: inline;"
            v-if="unameStatus == 4"
          ></em>
          <i style="display: inline;" v-if="unameStatus == 3"
            >此邮箱已被其他用户注册</i
          >
          <i style="display: inline;" v-if="unameStatus == 1"
            >请输入您的注册邮箱</i
          >
          <i style="display: inline;" v-if="unameStatus == 2"
            >请输入正确的邮箱格式</i
          >
        </li>
        <li>
          <span>密码：</span>
          <input
            type="password"
            id="upwd"
            placeholder="请输入密码"
            v-model="upwd"
            @blur="checkPwd"
          />
          <em
            class="icon_error"
            style="display: inline;"
            v-if="upwdStatus == 1 || upwdStatus == 2"
          ></em>
          <em
            class="icon_ok"
            style="display: inline;"
            v-if="upwdStatus == 3"
          ></em>
          <i style="display: inline;" v-if="upwdStatus == 1">请输入您的密码</i>
          <i style="display: inline;" v-if="upwdStatus == 2"
            >密码长度应为6~12个字符之间</i
          >
        </li>
        <li>
          <span>重复密码：</span>
          <input
            type="password"
            id="upwd2"
            placeholder="请重复输入密码"
            v-model="upwd2"
            @blur="checkPwd2"
          />
          <em
            class="icon_error"
            style="display: inline;"
            v-if="upwd2Status == 1"
          ></em>
          <em
            class="icon_ok"
            style="display: inline;"
            v-if="upwd2Status == 2"
          ></em>
          <i style="display: inline;" v-if="upwd2Status == 1">两次密码不一样</i>
        </li>
        <li>
          <span>手机号：</span>
          <input
            type="text"
            id="phone"
            placeholder="请输入手机号"
            v-model="phone"
            @blur="checkPhone"
          />
          <em
            class="icon_error"
            style="display: inline;"
            v-if="phoneStatus == 3 || phoneStatus == 1 || phoneStatus == 2"
          ></em>
          <em
            class="icon_ok"
            style="display: inline;"
            v-if="phoneStatus == 4"
          ></em>
          <i style="display: inline;" v-if="phoneStatus == 3"
            >此手机号已被其他用户注册</i
          >
          <i style="display: inline;" v-if="phoneStatus == 1"
            >请输入您的手机号</i
          >
          <i style="display: inline;" v-if="phoneStatus == 2"
            >请输入正确的手机号格式</i
          >
        </li>
        <li>
          <span>验证码：</span>
          <input
            type="text"
            class="inp_yzm"
            placeholder="请输入验证码"
            v-model="code"
          />
          <img src="../assets/images/yzm.png" alt="" />
          <strong class="icon_refresh"></strong>
        </li>
        <li class="li_checkbox">
          <label>
            <input type="checkbox" v-model="agree" />
            <span>我已阅读并同意用户注册协议</span>
          </label>
        </li>
        <li class="li_btn">
          <button type="button" v-if="agree" @click="doReg">提交注册</button>
          <button v-else class="disabled" disabled>提交注册</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agree: true, //同意条款
      uname: "",
      upwd: "",
      upwd2: "",
      phone: "",
      code: "",
      unameStatus: 0, //姓名 的状态
      upwdStatus: 0, //密码 的状态
      upwd2Status: 0, //重复密码 的状态
      phoneStatus: 0, //手机号的状态
    };
  },
  methods: {
    doReg() {
      console.log(this.uname, this.upwd, this.upwd2, this.phone, this.code);

      // 所有信息都正确, 才允许注册
      if (
        this.unameStatus == 4 &&
        this.upwdStatus == 3 &&
        this.upwd2Status == 2 &&
        this.phoneStatus == 4
      ) {
        //注册
        let url = "user_register.php";
        let params = `uname=${this.uname}&upwd=${this.upwd}&phone=${this.phone}`;

        this.axios.post(url, params).then((res) => {
          console.log(res);

          let { code, uid, uname } = res.data;
          if (code == 1) {
            alert("恭喜您，注册成功！即将为您跳转到刚才的页面。");

            // vuex提供的变量 $store
            // commit(方法名, 参数)
            this.$store.commit("updateUser", { uid, uname });

            this.$router.push({ path: "/" });
          } else {
            alert("注册失败!");
          }
        });
      } else {
        alert("请确保所有信息正确, 再注册!!");
      }
    },
    checkName() {
      // 检查uname 格式, 具体情况:
      // 0. 默认状态
      // 1. 空
      if (this.uname.trim() == "") {
        this.unameStatus = 1;
        console.log("空");
        return;
      }

      // 2. 格式错误
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(this.uname)) {
        this.unameStatus = 2;
        console.log("格式错误");
        return;
      }

      // 3. 已存在
      let url = "user_check_uname.php?uname=" + this.uname;
      this.axios.get(url).then((res) => {
        console.log(res);

        let { code } = res.data;

        if (code == 1) {
          this.unameStatus = 3;
        } else {
          this.unameStatus = 4;
        }
      });

      // 4. 正确
    },
    // 密码框: @blur 失去焦点触发
    checkPwd() {
      this.checkPwd2();

      //密码的状态:
      // 0. 默认 不显示提示
      // 1. 空密码, 提示 请输入您的密码
      let pwd_length = this.upwd.trim().length;
      if (pwd_length == 0) return (this.upwdStatus = 1);

      // 2. 密码长度不足: 密码长度应为6~12个字符之间
      if (pwd_length < 6 || pwd_length > 12) return (this.upwdStatus = 2);

      // 3. 正确
      this.upwdStatus = 3;
    },
    // 检查重复密码
    checkPwd2() {
      // 状态值设定:
      // 0. 默认  不显示提示
      // 1. 两次密码不一样
      if (this.upwd != this.upwd2) return (this.upwd2Status = 1);
      // 2. 两次密码相同
      this.upwd2Status = 2;
    },
    checkPhone() {
      // 0. 默认 不显示
      // 1. 空  提示: 请填写您的手机号
      if (this.phone.trim().length == 0) return (this.phoneStatus = 1);

      // 2. 格式错误 提示: 请输入正确的手机号码
      if (!/^1[3-9]\d{9}$/.test(this.phone)) return (this.phoneStatus = 2);

      // 3. 手机号已注册  提示: 此手机号已被其他用户绑定,  例如: 15555555555
      //    main.js中需要使用 原版的接口地址 user_check_phone.php?phone=xxx
      let url = "user_check_phone.php?phone=" + this.phone;
      this.axios.get(url).then((res) => {
        console.log(res);
        let { code } = res.data;
        if (code == 1) {
          this.phoneStatus = 3;
        } else {
          this.phoneStatus = 4;
        }
      });

      // 4. 手机号正确
    },
  },
};
</script>

<style></style>
